import styled from "@emotion/styled";
import { Drawer, Hilo, Logo, MenuButton, WhatsappButton } from "components";
import {
  QueHacemos,
  OtrosServicios,
  Como,
  FlexImagenes,
  ComoTrabajamos,
  NuestroAdn,
  ParteDelEquipo,
  Marcas1,
  Marcas2,
  Conectamos,
  ContactInfo,
  ContactForm,
} from "sections/Desktop";
import { useWindowSize } from "react-use";
import _ from "lodash";
import { useState } from "react";

const Root = styled.div`
  position: relative;
`;

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const HiloContainer = styled(Container)`
  position: absolute;
  z-index: 3;
`;

const PinkContainer = styled(Container)`
  background-color: #ed008e;
`;

const InnerContainer = styled.div`
  position: relative;
  width: 1200px;
`;

const BlueContainer = styled(Container)`
  background-color: #191c52;
`;

const Desktop: React.FC = () => {
  //const { width } = useWindowSize();

  const pathWidth = 1200;
  const height = (16280 / 1896) * pathWidth;
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <Root id="root" style={{ height }}>
      <WhatsappButton
        onClick={() =>
          window.open(
            "https://api.whatsapp.com/send/?phone=5491158677154",
            "_blank"
          )
        }
      />

      <MenuButton onClick={() => setDrawerOpen(true)} />
      <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)} />
      <HiloContainer style={{ height, top: pathWidth * 0.3 }}>
        <InnerContainer>
          <Hilo width={pathWidth} height={height} />
        </InnerContainer>
      </HiloContainer>
      <Container style={{ top: 0 }}>
        <InnerContainer>
          <Logo width={300} height={300} />
        </InnerContainer>
      </Container>
      <Container style={{ top: "13.205%" }}>
        <InnerContainer id="que-hacemos">
          <QueHacemos />
        </InnerContainer>
      </Container>
      <Container style={{ top: "18.755%" }}>
        <InnerContainer id="servicios">
          <Como />
        </InnerContainer>
      </Container>
      {/* como trabajamos - old */}
      {/* <PinkContainer style={{ top: "27.055%", paddingBottom: pathWidth * 0.4 }}>
                <InnerContainer>
                    <FlexImagenes />
                </InnerContainer>
            </PinkContainer> */}
      {/* <PinkContainer style={{ top: "32.855%", paddingBottom: pathWidth * 0.079 }}>
                <InnerContainer>
                    <ComoTrabajamos />
                </InnerContainer>
            </PinkContainer> */}
      <PinkContainer
        style={{
          top: "30.785%",
          paddingTop: "218px",
          paddingBottom: pathWidth * 0.052,
        }}
      >
        <InnerContainer>
          <ComoTrabajamos />
        </InnerContainer>
      </PinkContainer>
      <Container style={{ top: "40.63%" }}>
        <InnerContainer>
          <NuestroAdn />
        </InnerContainer>
      </Container>
      <PinkContainer
        style={{ top: "46.63%", paddingTop: "100px", paddingBottom: "100px" }}
      >
        <InnerContainer>
          <OtrosServicios />
        </InnerContainer>
      </PinkContainer>
      <Container style={{ top: "52.03%" }}>
        <InnerContainer id="equipo">
          <ParteDelEquipo />
        </InnerContainer>
      </Container>
      <Container style={{ top: "70.73%" }}>
        <InnerContainer id="pymes">
          <Marcas1 />
        </InnerContainer>
      </Container>
      <Container style={{ top: "75.18%" }}>
        <InnerContainer>
          <Marcas2 />
        </InnerContainer>
      </Container>
      <PinkContainer style={{ top: "78.195%", height: 1018, paddingTop: 360 }}>
        <InnerContainer>
          <Conectamos />
        </InnerContainer>
      </PinkContainer>
      <BlueContainer style={{ top: "86.425%", paddingTop: 70, height: 1820 }}>
        <InnerContainer id="contactanos">
          <ContactForm />
        </InnerContainer>
      </BlueContainer>
      <BlueContainer style={{ top: "93.625%", paddingTop: 100 }}>
        <InnerContainer>
          <ContactInfo />
        </InnerContainer>
      </BlueContainer>
    </Root>
  );
};

export default Desktop;
