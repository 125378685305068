import styled from "@emotion/styled";
import React from "react";

const FlexWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index: 10;
`

const ContactTyphography = styled.div`
    color: white;
    font-size: 2rem;
    margin-left: 15px;
    font-weight: bold;
    font-family: "HelveticaNeueLTStd-Roman";
    font-size: 1.3rem;
    @media (max-width: 1200px) {
        font-size: 0.8rem;
        margin-left:  5px;
        padding-top: 5px;
    }
`

const ContactIcon = styled.img`
    width: 40px;
    height: 40px;
    @media (max-width: 1200px) {
        width: 18px;
        height: 18px;
    }
`

interface IProps {
    logoSrc: string;
    content: string;
    onClick?: () => void;
}

export const ContactItem: React.FC<IProps> = ({ logoSrc, content, onClick }) => {
    return <FlexWrapper onClick={onClick}>
        <ContactIcon width={40} height={40} src={logoSrc} alt="platform" />
        <ContactTyphography>{content}</ContactTyphography>
    </FlexWrapper>
}