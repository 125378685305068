import styled from "@emotion/styled"
import { Heading, ServiceCard } from "components"

const FlexWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
`

const CardsFlex = styled.div`
    display: flex;
    justify-content: center;
`

const Margin = styled.div`
    width: 40px;
`

const TitleMargin = styled.div`
    height: 40px;
`

export const OtrosServicios = () => {
    return <FlexWrapper>
            <Heading>Otros Servicios</Heading>
            <TitleMargin />
            <CardsFlex>
                <ServiceCard>Consultoría integral de marketing y comunicación para pequeñas y medianas empresas.</ServiceCard>
                <Margin />
                <ServiceCard>Trade marketing</ServiceCard>
            </CardsFlex>
    </FlexWrapper>
}