import styled from "@emotion/styled";
import { ReactNode } from "react";


const MiembroDeEquipoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 600px;
`

const Name = styled.h2`
    font-family: "HelveticaNeueLTStd-Bd";
    font-size: 2rem;
    font-weight: bold;
    line-height: 3.3rem;
    @media (max-width: 1200px) {
        font-size: 0.9rem;
        line-height: 1rem;
    }
`

const Title = styled.h3`
    font-family: "HelveticaNeueLTStd-Roman";
    font-size: 1.3rem;
    line-height: 1;
    margin-bottom: 40px;
    text-transform: uppercase;
    @media (max-width: 1200px) {
        font-size: 0.7rem;
        margin-bottom: 5px;
        font-height: 0.8rem;
    }
`

const Description = styled.p`
    font-family: "HelveticaNeueLTStd-Roman";
    font-size: 1.1rem;
    line-height: 1.66rem;
    text-align: justify;
    @media (max-width: 1200px) {
        text-align: left;
        font-size: 0.65rem;
        line-height: 0.65rem;
    }
`

interface IProps {
    name: string;
    title: string;
    children: ReactNode;
    style?: any;
}

export const MiembroDeEquipo: React.FC<IProps> = ({ name, title, children, style }) => {
    return <MiembroDeEquipoWrapper style={style}>
        <Name>
            {name}
        </Name>
        <Title>
            {title}
        </Title>
        <Description>
            {children}
        </Description>
    </MiembroDeEquipoWrapper>
}