import styled from "@emotion/styled";
import {
  Heading,
  Heading2,
  SendButton,
  TextAreaInput,
  TextInput,
} from "components";
import emailjs from "@emailjs/browser";
import { useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ContactFormWrapper = styled.div`
  width: 800px;
  height: 320px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InfoFlexWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 80%;
  width: 460px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
`;
const FormTitle = styled(Heading)`
  margin-bottom: 24px;
`;

const InputLabel = styled(Heading2)`
  margin: 0;
  margin-bottom: 8px;
  font-size: 1.1rem;
`;

const FormInputWrapper = styled("div")`
  width: 100%;
  margin-bottom: 24px;
  z-index: 10;
`;

export const ContactForm = () => {
  const form = useRef<HTMLFormElement>(null);
  const [disabled, setDisabled] = useState(false);
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (form.current) {
      setDisabled(true);
      emailjs
        .sendForm(
          "service_h09qoy1",
          "template_cecjqxo",
          form.current,
          "hr1rjIImYOtibwtkh"
        )
        .then(
          (result) => {
            toast("Mensaje enviado!", { type: "success" });
            form.current?.reset();
          },
          (error) => toast("Error al enviar mensaje.", { type: "error" })
        ).finally(()=>setDisabled(false));
    }
  };

  return (
    <FlexWrapper>
      <ToastContainer />
      <ContactFormWrapper>
        <InfoFlexWrapper ref={form} onSubmit={onSubmit}>
          <FormTitle>Escribinos</FormTitle>
          <FormInputWrapper>
            <InputLabel>Nombre</InputLabel>
            <TextInput required name="from_name" maxLength={36}/>
          </FormInputWrapper>
          <FormInputWrapper>
            <InputLabel>Email</InputLabel>
            <TextInput required type="email" name="reply_to"  maxLength={36}/>
          </FormInputWrapper>
          <FormInputWrapper>
            <InputLabel>Mensaje</InputLabel>
            <TextAreaInput required name="message" rows={5} maxLength={500}/>
          </FormInputWrapper>
          <SendButton disabled={disabled} type="submit">Enviar</SendButton>
        </InfoFlexWrapper>
      </ContactFormWrapper>
    </FlexWrapper>
  );
};
