import styled from "@emotion/styled"
import { Heading2 } from "components"
import logoComafiSrc from "assets/logo-comafi.svg";
import logoStellaArtoisSrc from "assets/logo-stella-artois.svg";
import logoCepasSrc from "assets/logo-cepas.svg";
import logoMotorolaSrc from "assets/logo-motorola.svg";


const FlexWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #191c52;
`

const Marcas1Wrapper = styled.div`
    width: 78%;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #191c52;
`

const Marcas1Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100%;
    align-items: center;
    height: 260px;
`

const HeadingMargin = styled.div`
    height: 10px;
`


export const Marcas2 = () => {
    return <FlexWrapper>
        <Marcas1Wrapper>
            <Heading2 style={{ maxWidth: 700, textAlign: "center", marginLeft: -30 }}>Marcas con las que hilamos acciones de relacionamiento:</Heading2>
            <Marcas1Grid>
                <img src={logoComafiSrc} width={100} height={100}/>
                <img src={logoStellaArtoisSrc} width={100} height={100}/>
                <img src={logoCepasSrc} width={100} height={100}/>
                <img src={logoMotorolaSrc} width={100} height={100}/>
            </Marcas1Grid>
        </Marcas1Wrapper>
    </FlexWrapper>
}