import styled from "@emotion/styled";
import { CloseButton } from "components/CloseButton";
import { ContactButton } from "components/ContactButton";
import { ReactNode } from "react";

interface DrawerContainerProps {
  open: boolean;
}

const DrawerContainer = styled.div<DrawerContainerProps>`
  height: 100vh;
  width: 450px;
  transition: transform 0.5s;
  ${({ open }) =>
    open ? "transform: translate(0);" : "transform:translate(-100%);"}
  z-index: 20;
  background-color: #9fcb39;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 50px;
  padding-left: 40px;
  max-width: 100vw;
  padding-right: 40px;
  @media (max-width: 1280px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const BackgroundOverlay = styled.div<DrawerContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  ${({ open }) => (open ? "opacity: 0.7;" : "opacity: 0; visibility: hidden;")}
  z-index: 19;
  transition: opacity 0.2s;
`;

const MenuList = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
`;

const RightButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const CenteredButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const DrawerButton = styled.button`
  font-family: "HelveticaNeueLTStd-Blk";
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 20px;
  color: #191c52;
  background: none;
  border: none;
  text-align: left;
  width: max-content;
`;

interface IProps {
  open: boolean;
  onClose: () => void;
}

const scrollToTargetAdjusted = (id: string) =>{
  const element = document.getElementById(id);
  const headerOffset = 45;
  const elementPosition = element!.getBoundingClientRect().top;
  const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

  window.scrollTo({
       top: offsetPosition,
       behavior: "smooth"
  });
}

export const Drawer: React.FC<IProps> = ({ open, onClose }) => {
  const strollIntoView = (id: string) => {
    scrollToTargetAdjusted(id);
    onClose();
  };
  return (
    <>
      <BackgroundOverlay open={open} onClick={() => onClose()} />
      <DrawerContainer open={open}>
        <RightButtonWrapper>
          <CloseButton onClick={() => onClose()} />
        </RightButtonWrapper>
        <MenuList>
          <DrawerButton onClick={()=>strollIntoView("que-hacemos")}>Qué hacemos</DrawerButton>
          <DrawerButton onClick={()=>strollIntoView("servicios")}>Servicios</DrawerButton>
          <DrawerButton onClick={()=>strollIntoView("equipo")}>Equipo</DrawerButton>
          <DrawerButton onClick={()=>strollIntoView("pymes")}>Confían en nosotros</DrawerButton>
        </MenuList>
        <CenteredButtonWrapper>
          <ContactButton onClick={()=>strollIntoView("contactanos")}>Contactanos</ContactButton>
        </CenteredButtonWrapper>
      </DrawerContainer>
    </>
  );
};
