import styled from "@emotion/styled";

import { Heading, ServiceCard } from "components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css/pagination";
import "./overrides.css";

const FlexWrapper = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
`;

const OtrosServiciosWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  z-index: 10;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
`;

export const OtrosServicios = () => {
  return (
    <FlexWrapper>
      <OtrosServiciosWrapper>
        <HeaderWrapper>
          <Heading>Otros Servicios</Heading>
        </HeaderWrapper>
        <div style={{width: "100%"}}>
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
            modules={[Pagination]}
            pagination={{
              bulletActiveClass: "white-bullet",
            }}
          >
            <SwiperSlide style={{ display: "flex", justifyContent: "center" }}>
              <ServiceCard>
                Consultoría integral de marketing y comunicación para pequeñas y
                medianas empresas.
              </ServiceCard>
            </SwiperSlide>
            <SwiperSlide style={{ display: "flex", justifyContent: "center" }}>
              <ServiceCard>Trade marketing</ServiceCard>
            </SwiperSlide>
          </Swiper>
        </div>
      </OtrosServiciosWrapper>
    </FlexWrapper>
  );
};
