import styled from "@emotion/styled"
import { Heading2 } from "components"
import logoClaroSrc from "assets/logo-claro.svg";
import logoVwSrc from "assets/logo-vw.svg";
import logoSamsungSrc from "assets/logo-samsung.svg";
import logoPeugeotSrc from "assets/logo-peugeot.svg";
import logoPersonalSrc from "assets/logo-personal.svg";
import logoPedidosYaSrc from "assets/logo-pedidos-ya.svg";
import logoMovistarSrc from "assets/logo-movistar.svg";
import logoAgroresultadistaSrc from "assets/logo-agroresultadista.svg";



const FlexWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #191c52;
    text-align: center;
    height: 700px;
`

const Marcas1Wrapper = styled.div`
    width: 80%;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #191c52;
    `

const Marcas1Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    align-items: center;
    justify-items: center;
    row-gap: 15px;
    height: 260px;
`

const HeadingMargin = styled.div`
    height: 30px;
`


export const Marcas1 = () => {
    return <FlexWrapper>
        <Marcas1Wrapper>
            <Heading2>Marcas con las que hilamos integralmente sus programas de relacionamiento:</Heading2>
            <HeadingMargin />
            <Marcas1Grid>
                <img src={logoClaroSrc} width={60} height={60} />
                <img src={logoVwSrc} width={60} height={60} />
                <img src={logoSamsungSrc} width={60} height={60} />
                <img src={logoPeugeotSrc} width={60} height={60} />
                <img src={logoPersonalSrc} width={60} height={60} />
                <img src={logoPedidosYaSrc} width={60} height={60} />
                <img src={logoMovistarSrc} width={60} height={60} />
                <img src={logoAgroresultadistaSrc} width={60} height={60} />
            </Marcas1Grid>
        </Marcas1Wrapper>
    </FlexWrapper>
}