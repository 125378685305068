import styled from "@emotion/styled";

export const Paragraph = styled.p`
    font-family: "HelveticaNeueLTStd-Roman";
    font-size: 1.3rem;
    line-height: 1.5rem;
    @media (max-width: 1200px) {
        font-size: 1rem;
        line-height: 1.1rem;
    }
`