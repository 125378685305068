import styled from "@emotion/styled"

const FlexWrapper = styled.div`
    display: flex;
    justify-content: center;
`

const ConectamosWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

const ConectamosTyphography = styled.h1`
    color: white;
    font-family: "HelveticaNeueLTStd-Roman";
    font-size: 1.7rem;
    line-height: 1.8rem;
    font-weight: 300;
`

export const Conectamos = () => {
    return <FlexWrapper>
            <ConectamosWrapper>
                <ConectamosTyphography style={{marginLeft: 30}}>
                    Conectamos
                </ConectamosTyphography>
                <ConectamosTyphography>
                    experiencias
                </ConectamosTyphography>
                <ConectamosTyphography style={{marginLeft: 50, fontSize: "2.1rem", marginTop: 3}}>
                    que perduran
                </ConectamosTyphography>
            </ConectamosWrapper>
    </FlexWrapper>
}