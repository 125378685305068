import styled from "@emotion/styled"

import { Heading } from "components"
import { Paragraph } from "components/Paragraph"
import logoSrc from "assets/hilo-logo2.svg"


const FlexWrapper = styled.div`
    color: #191c52;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 10%;
`

const NuestroAdnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 600px;
`

const LogoWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;;
`

const HeadingMargin = styled.div`
    height: 55px;
`

const ParagraphMargin = styled.div`
    height: 30px;
`

export const NuestroAdn = () => {
    return <FlexWrapper>
        <NuestroAdnWrapper>
        <Heading>Nuestro ADN</Heading>
            <HeadingMargin />
            <Paragraph>
                Somos una agencia chica con el expertise de una gran agencia, quiénes la integramos tenemos vasta experiencia liderando areas de Trade marketing, publicidad, customer experience y loyalty marketing en agencias y empresas multinacionales.
            </Paragraph>
            <ParagraphMargin />
            <Paragraph>
                Nuestro modelo es flexible y adaptativo, sabemos cómo pensar estratégicamente y creativamente para llegar a los objetivos deseados.<br />
                Nuestra propuesta no nace de un brief sino de buscar sumarnos a tus equipos internos, para poder profundizar el entendimiento de la necesidad, del proyecto y de la solución que damos.
            </Paragraph>
            <ParagraphMargin />
            <Paragraph>
                Vinculamos, conectamos, generamos relaciones de confianza, perdurables en el tiempo y cercanas, somos así. de nacimiento.
            </Paragraph>
        </NuestroAdnWrapper>
        <LogoWrapper>
            <img height={400} width={400} src={logoSrc} alt="logo" />
        </LogoWrapper>
    </FlexWrapper>
}