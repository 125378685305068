import styled from "@emotion/styled";
import { ReactNode } from "react";


const ComoItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    text-align: center;
    @media (max-width: 1200px) {
        width: 200px;
    }
`

const ComoItemImgWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 200px;
    width: 200px;
    @media (max-width: 1200px) {
        height: 120px;
        width: 120px;
    }
    background-color: #9ecb3a;
`

const ComoItemImg = styled.div`
    img {
        width: 100px !important;
        height: 100px !important;
        @media (max-width: 1200px) {
            height: 80px !important;
            width: 80px !important;
        }
    }
`

const ComoItemTitleWrapper = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom: 148px;
    @media (max-width: 1200px) {
        margin-bottom: 35px;
    }
`

const ComoItemTitle = styled.h2`
    font-weight: bold;
    position: absolute;
    font-size: 30px;
    bottom: -68px;
    height: 40px;
    vertical-alignment: top;
    font-family: "HelveticaNeueLTStd-Bd";
    @media (max-width: 1200px) {
        font-size: 20px;
        bottom: -24px;
    }
    line-height: 43px;
`

const ComoItemContent = styled.div`
    font-family: "HelveticaNeueLTStd-Roman";
    font-size: 20px;
    @media (max-width: 1200px) {
        font-size: 12px;
    }
    line-height: 30px;

`

interface IProps {
    title: string;
    logoSrc: string;
    children: ReactNode
}
export const ComoItem: React.FC<IProps> = ({ title, logoSrc, children }) => {
    return <ComoItemWrapper>
        <ComoItemTitleWrapper>
            <ComoItemImgWrapper>
                <ComoItemImg>

                    <img src={logoSrc} />
                </ComoItemImg>
            </ComoItemImgWrapper>
            <ComoItemTitle>
                {title}
            </ComoItemTitle>
        </ComoItemTitleWrapper>
        <ComoItemContent>
            {children}
        </ComoItemContent>
    </ComoItemWrapper>
}