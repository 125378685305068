import styled from "@emotion/styled"

import { Heading } from "components"
import { Paragraph } from "components/Paragraph"
import logoSrc from "assets/hilo-logo2.svg"


const FlexWrapper = styled.div`
    color: #191c52;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const NuestroAdnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
`

const LogoWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
`

const ParagraphMargin = styled.div`
    height: 5px;
`

const HeaderWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
`

const SmallerParagraph = styled(Paragraph)`
    font-size: 0.65rem !important;
    line-height: 0.70rem;
`

export const NuestroAdn = () => {
    return <FlexWrapper>
        <NuestroAdnWrapper>
            <HeaderWrapper><Heading>Nuestro ADN</Heading></HeaderWrapper>
            <SmallerParagraph>
                Somos una agencia chica con el expertise de una gran agencia, quiénes la integramos tenemos vasta experiencia liderando areas de Trade marketing, publicidad, customer experience y loyalty marketing en agencias y empresas multinacionales.
            </SmallerParagraph>
            <ParagraphMargin />
            <SmallerParagraph>
                Nuestro modelo es flexible y adaptativo, sabemos cómo pensar estratégicamente y creativamente para llegar a los objetivos deseados.<br />
                Nuestra propuesta no nace de un brief sino de buscar sumarnos a tus equipos internos, para poder profundizar el entendimiento de la necesidad, del proyecto y de la solución que damos.
            </SmallerParagraph>
            <ParagraphMargin />
            {/* <SmallerParagraph>
                Vinculamos, conectamos, generamos relaciones de confianza, perdurables en el tiempo y cercanas, somos así. de nacimiento.
            </SmallerParagraph> */}
        </NuestroAdnWrapper>
        {/* <LogoWrapper>
            <img height={100} width={100} src={logoSrc} alt="logo" />
        </LogoWrapper> */}
    </FlexWrapper>
}