import styled from "@emotion/styled"
import { useState } from "react"
import { Transition } from "react-transition-group"
import { Heading } from "components"
import { Paragraph } from "components/Paragraph"
import { Subheading } from "components/Subheading"

const FlexWrapper = styled.div`
    width: 100%;
    z-index: 4;
    display: flex;
    padding-left: 12%;
`

const QueHacemosWrapper = styled.div`
    width: 80%;
    color: #191c52;
`

const HeadingMargin = styled.div`
    height: 25%;
`

const SubheadingMargin = styled.div`
    height: 15%;
`

export const QueHacemos = () => {
    return <FlexWrapper>
        <QueHacemosWrapper>
            <Heading>Qué Hacemos</Heading>
            <HeadingMargin />
            <Subheading>Fortalecemos relaciones, fidelizamos,
                motivamos e incentivamos a clientes,
                equipos internos y partners estratégicos.</Subheading>
            <SubheadingMargin />
            <Paragraph>Somos una agencia especializada en Loyalty
                Marketing. Nuestra propuesta de valor es entender la
                necesidad, diseñar y ejecutar las mejores estrategias
                de fidelización, incentivos y relacionamiento de
                marcas, proyectos o empresas.</Paragraph>
        </QueHacemosWrapper>
    </FlexWrapper>
}