import styled from "@emotion/styled";

export const ContactButton = styled("button")`
    background-color: #191C52;
    color: white;
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 23px;
    padding-bottom: 15px;
    font-family: 'HelveticaNeueLTStd-Blk';
    font-size: 1.7rem;
    text-align: center;
    vertical-align: middle;
    border: none;
    box-shadow: none;
    border-radius: 27px;
    z-index: 10;
    cursor: pointer;
    align-self: flex-end;
`