import styled from "@emotion/styled"
import logoSrc from "assets/hilo-logo.svg"


const LogoWrapper = styled.div`
    width: inherit;
    display: flex;
    justify-content: flex-end;
    max-width: 100%;
    padding-top: 110px;
    padding-right: 100px;
    @media (max-width: 1200px) {
        padding-top: 30px;
        padding-right: 30px;
        padding-bottom: 100px;
    }
`


interface IProps {
    width?: number;
    height?: number;
}

export const Logo: React.FC<IProps> = ({ width, height }) => {
    return <LogoWrapper><img style={{ width, height}} src={logoSrc} alt="logo" /></LogoWrapper>
}