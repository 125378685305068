import styled from "@emotion/styled";

export const Heading = styled.h1`
    font-family: "HelveticaNeueLTStd-Blk";
    font-size: 3.2rem;
    line-height: 4.7rem;
    text-transform: uppercase;
    font-weight: bold;
    @media (max-width: 1200px) {
        font-size: 1.1rem;
        line-height: 1.2rem;
    }
`