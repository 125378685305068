import styled from "@emotion/styled"
import { ComoItem, Heading } from "components"
import { Subheading } from "components/Subheading"
import fidelizacionSrc from "assets/fidelizacion.svg"
import incentivosSrc from "assets/incentivos.svg"
import accionesDeRelacionamientoSrc from "assets/acciones-de-relacionamiento.svg"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css/pagination";
import "./overrides.css";
import { Pagination } from "swiper";

// Import Swiper styles
import 'swiper/css';

const FlexWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #191c52;
`

const ComoWrapper = styled.div`
    width: 80%;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
`

const HeadingMargin = styled.div`
    height: 20px;
`

const SubheadingMargin = styled.div`
    height: 10px;
`

const ComoItemFlex = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    padding-bottom: 140px;
`
export const Como = () => {
    return <FlexWrapper>
        <Heading>Cómo</Heading>
        <HeadingMargin />
        <ComoWrapper>
            <Subheading>Diseñando e implementando programas de:</Subheading>
            <SubheadingMargin />
            <ComoItemFlex>
                <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                    modules={[Pagination]}
                    pagination={true}
                >
                    <SwiperSlide style={{display:"flex", justifyContent:"center"}}><ComoItem title="Fidelización" logoSrc={fidelizacionSrc}>
                        <>Programas que premien a tus clientes leales. Identificando y potenciando Brand Lovers, esos consumidores emocionalmente vinculados con la marca.</>
                    </ComoItem></SwiperSlide>
                    <SwiperSlide style={{display:"flex", justifyContent:"center"}}><ComoItem title="Incentivos" logoSrc={incentivosSrc}>
                        <>Motivar y acelerar el comportamiento productivo de tus equipos internos o partners estratégicos, logrando mejores resultados de eficiencia, desempeño y fidelidad con la marca.</>
                    </ComoItem></SwiperSlide>
                    <SwiperSlide style={{display:"flex", justifyContent:"center"}}><ComoItem title="Acciones de relacionamiento" logoSrc={accionesDeRelacionamientoSrc}>
                        <>Diseñamos acciones de relacionamiento estratégico, experiencias, acciones BTL y eventos, para dar soporte a los distintos programas de Loyalty.</>
                    </ComoItem></SwiperSlide>
                </Swiper>
            </ComoItemFlex>
        </ComoWrapper>
    </FlexWrapper>
}