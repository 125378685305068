import styled from "@emotion/styled";
import { ReactNode } from "react";


interface ComoTrabajamosItemWrapperProps {
    backgroundColor?: string;
}

const ComoTrabajamosItemWrapper = styled.div<ComoTrabajamosItemWrapperProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 18.5%;
    max-width: 18.5%;
    min-width: 18.5%;
    height: 500x;
    text-align: center;
    color: white;
    border-radius: 20px;
    ${({ backgroundColor }) => backgroundColor ? `background-color:${backgroundColor};` : ""}
    padding: 20px;
    margin: 1.5%;
`

const ComoTrabajamosItemTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 43%;
    width: 100%;
`

const ComoTrabajamosItemTitle = styled.h2`
    font-weight: bold;
    font-family: "HelveticaNeueLTStd-Bd";
    text-transform: uppercase;
    margin-top: 50px;
    font-size: 20px;
    line-height: 25px;
`

const ComoTrabajamosItemContent = styled.div`
    font-family: "HelveticaNeueLTStd-Roman";
    font-size: 1.1rem;
    margin-top: 60px;
`


interface IProps {
    title: string;
    logoSrc: string;
    children: ReactNode;
    backgroundColor?: string;
}
export const ComoTrabajamosItem: React.FC<IProps> = ({ title, logoSrc, children, backgroundColor }) => {
    return <ComoTrabajamosItemWrapper backgroundColor={backgroundColor}>
        <ComoTrabajamosItemTitleWrapper>
            <img width={100} height={100} src={logoSrc} />
            <ComoTrabajamosItemTitle>
                {title}
            </ComoTrabajamosItemTitle>
        </ComoTrabajamosItemTitleWrapper>
        <ComoTrabajamosItemContent>
            {children}
        </ComoTrabajamosItemContent>
    </ComoTrabajamosItemWrapper>
}