import styled from "@emotion/styled";
import icon from "./icon.png";

export const WhatsappButton = styled.button`
  border: none;
  border-radius: 50%;
  padding: 10px;
  background: #25D366 url("${icon}") no-repeat center;
  background-size: 75%;
  width: 60px;
  height: 60px;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 18;
  box-shadow: 6px 8px 19px 1px rgba(0,0,0,0.4);
  @media (min-width: 1280px) {
    width: 90px;
    height: 90px;
    bottom: 50px;
    right: 50px;
  }
`;
