import styled from "@emotion/styled"
import { ComoTrabajamosItemMobile, Heading } from "components"
import consultoriaEstrategicaSrc from "assets/consultoria-estrategica.svg";
import implementacionSrc from "assets/implementacion.svg";
import tecnologiaSrc from "assets/tecnologia.svg"
import gestionDelProgramaSrc from "assets/gestion-del-programa.svg";
import analiticaSrc from "assets/analitica.svg";


const FlexWrapper = styled.div`
    width: 100%;
    z-index: 4;
    display: flex;
    justify-content: center;
`

const ComoTrabajamosWrapper = styled.div`
    width: 90%;
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
`

const HeadingMargin = styled.div`
    height: 33px;
`

const ComoTrabajamosItemsFlex = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`

export const ComoTrabajamos = () => {
    return <FlexWrapper><ComoTrabajamosWrapper>
        <Heading>
            Cómo Trabajamos
        </Heading>
        <HeadingMargin />
        <ComoTrabajamosItemsFlex>
            <ComoTrabajamosItemMobile backgroundColor="#ee1a99" title="Consultoría Estratégica" logoSrc={consultoriaEstrategicaSrc}>
                <>Conceptualizamos la propuesta en base a los objetivos planteados, aprendemos del perfil de quienes participarán. Diseñamos estratégicamente y creativamente el programa y su plan de acción.</>
            </ComoTrabajamosItemMobile>
            <ComoTrabajamosItemMobile title="Implementación" logoSrc={implementacionSrc}>
                <>Podemos ejecutar las acciones definidas para cada programa en su totalidad.</>
            </ComoTrabajamosItemMobile>
            <ComoTrabajamosItemMobile backgroundColor="#ee1a99" title="Tecnología" logoSrc={tecnologiaSrc}>
                <>Desarrollamos soluciones tecnológicas necesarias para la implementación y seguimiento de los programas.</>
            </ComoTrabajamosItemMobile>
            <ComoTrabajamosItemMobile title="Gestión del Programa" logoSrc={gestionDelProgramaSrc}>
                <>Acompañamos la implementación y operación del programa supervisando la calidad de los entregables y el cumplimiento de los objetivos propuestos.</>
            </ComoTrabajamosItemMobile>
            <ComoTrabajamosItemMobile backgroundColor="#ee1a99" title="Analítica, Reportes y Mejora Continua" logoSrc={analiticaSrc}>
                <>Creación de KPIs claves, para poder analizar evolución de los programas y necesidades de revision bajo el proceso de mejora continua.</>
            </ComoTrabajamosItemMobile>
        </ComoTrabajamosItemsFlex>
    </ComoTrabajamosWrapper></FlexWrapper>
}