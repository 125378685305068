import styled from "@emotion/styled";

export const Subheading = styled.p`
    font-family: "HelveticaNeueLTStd-Bd";
    font-size: 2rem;
    font-weight: bold;

    @media (max-width: 1200px) {
        font-size: 1.1rem;
        line-height: 1.2rem;
    }
`