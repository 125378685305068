import styled from "@emotion/styled"

import premiosClaroSrc from "assets/premios-claro.jpeg"
import olimpiadasNacionalesSrc from "assets/olimpiadas-nacionales.jpeg"
import personalViajesSrc from "assets/personal-viajes.jpeg"

const FlexWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`

const ImagenesFlex = styled.div`
    width: 80%;
    display: flex;
    justify-content: space-between;
    margin-top: -100px;
`


export const FlexImagenes = () => {
    return <FlexWrapper>
        <ImagenesFlex>
            <img width={300} height={230} src={premiosClaroSrc} />
            <img width={300} height={230} src={olimpiadasNacionalesSrc} />
            <img width={300} height={230} src={personalViajesSrc} />
        </ImagenesFlex>
    </FlexWrapper>
}