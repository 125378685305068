import styled from "@emotion/styled"

import logoSrc from "assets/hilo-logo3.svg"
import { ContactItem } from "components"
import icoMail from "assets/ico-mail.svg";
import icoWeb from "assets/ico-web.svg";
import icoWsp from "assets/ico-wsp.svg";
import icoInsta from "assets/ico-insta.svg";
import icoLn from "assets/ico-ln.svg";

const FlexWrapper = styled.div`
    display: flex;
    justify-content: center;
    z-index: 10;
`

const ContactInfoWrapper = styled.div`
    width: 800px;
    height: 320px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const LogoWrapper = styled.div`
    width: 315px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const InfoFlexWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 80%;
    width: 460px;
    padding-top: 10px;
    padding-bottom: 10px;
`

const link = (link: string) => window.open(link, "_blank")

export const ContactInfo = () => {
    return <FlexWrapper>
        <ContactInfoWrapper>
        <LogoWrapper><img src={logoSrc} alt="logo" width={210} height={210}/></LogoWrapper>
        <InfoFlexWrapper>
            <ContactItem logoSrc={icoMail} content="Hello@hiloagency.com" onClick={()=>link("mailto:hello@hiloagency.com")}/>
            <ContactItem logoSrc={icoWeb} content="www.hiloagency.com" onClick={()=>link("https://www.hiloagency.com")}/>
            <ContactItem logoSrc={icoWsp} content="115 867 7154" onClick={()=>link("https://api.whatsapp.com/send/?phone=5491158677154")}/>
            <ContactItem logoSrc={icoInsta} content="hilo.agency" onClick={()=>link("https://www.instagram.com/hiloagency")}/>
            <ContactItem logoSrc={icoLn} content="hiloagency" onClick={()=>link("https://www.linkedin.com/company/hiloagency/")}/>
        </InfoFlexWrapper>
        </ContactInfoWrapper>
    </FlexWrapper>
}