import styled from "@emotion/styled";

export const ServiceCard = styled.div`
    border-radius: 8px;
    padding: 32px;
    text-align: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: 'HelveticaNeueLTStd-Bd';
    font-size: 1.2rem;
    width: 340px;
    background-color: #F1329B;
    line-height: 1.4rem;
    @media (max-width: 1280px) {
        padding: 8px;
        font-size: 1rem;
        width: 250px;
        height: 100px;
    }
`