import styled from "@emotion/styled";
import { ReactNode } from "react";


interface ComoTrabajamosItemMobileWrapperProps {
    backgroundColor?: string;
}

const ComoTrabajamosItemMobileWrapper = styled.div<ComoTrabajamosItemMobileWrapperProps>`
    display: flex;
    align-items: center;
    color: white;
    border-radius: 20px;
    ${({ backgroundColor }) => backgroundColor ? `background-color:${backgroundColor};` : ""}
    padding: 20px;
    width: 100%;
`

const ComoTrabajamosItemMobileImgWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const ComoTrabajamosItemMobileTitle = styled.h2`
    font-weight: bold;
    font-family: "HelveticaNeueLTStd-Bd";
    text-transform: uppercase;
    padding-bottom: 10px;
    font-size: 0.9rem;
    line-height: 1rem;
`

const ComoTrabajamosItemMobileContent = styled.div`
    font-family: "HelveticaNeueLTStd-Roman";
    padding-left: 10px;
    font-size: 0.8rem;
`


interface IProps {
    title: string;
    logoSrc: string;
    children: ReactNode;
    backgroundColor?: string;
}
export const ComoTrabajamosItemMobile: React.FC<IProps> = ({ title, logoSrc, children, backgroundColor }) => {
    return <ComoTrabajamosItemMobileWrapper backgroundColor={backgroundColor}>
        <ComoTrabajamosItemMobileImgWrapper>
            <img width={60} height={60} src={logoSrc} />
        </ComoTrabajamosItemMobileImgWrapper>
        <ComoTrabajamosItemMobileContent>
            <ComoTrabajamosItemMobileTitle>
                {title}
            </ComoTrabajamosItemMobileTitle>
            {children}
        </ComoTrabajamosItemMobileContent>
    </ComoTrabajamosItemMobileWrapper>
}