import styled from "@emotion/styled";

export const SendButton = styled("button")`
    ${({disabled})=>disabled?"background-color: grey;":"background-color: #9FCB39;"}
    color: white;
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 14px;
    padding-bottom: 10px;
    font-family: 'HelveticaNeueLTStd-Blk';
    font-size: 1.4rem;
    text-align: center;
    vertical-align: middle;
    border: none;
    box-shadow: none;
    border-radius: 8px;
    z-index: 10;
    cursor: pointer;
    align-self: flex-end;
`