import styled from "@emotion/styled"
import { Heading2 } from "components"
import logoComafiSrc from "assets/logo-comafi.svg";
import logoStellaArtoisSrc from "assets/logo-stella-artois.svg";
import logoCepasSrc from "assets/logo-cepas.svg";
import logoMotorolaSrc from "assets/logo-motorola.svg";


const FlexWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #191c52;
    text-align: center;
`

const Marcas1Wrapper = styled.div`
    width: 80%;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #191c52;
    `

const Marcas1Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    align-items: center;
    justify-items: center;
    row-gap: 15px;
    height: 150px;
`


const HeadingMargin = styled.div`
    height: 10px;
`


export const Marcas2 = () => {
    return <FlexWrapper>
        <Marcas1Wrapper>
            <Heading2>Marcas con las que hilamos acciones de relacionamiento:</Heading2>
            <HeadingMargin />
            <Marcas1Grid>
                <img src={logoComafiSrc} width={60} height={60}/>
                <img src={logoStellaArtoisSrc} width={60} height={60}/>
                <img src={logoCepasSrc} width={60} height={60}/>
                <img src={logoMotorolaSrc} width={60} height={60}/>
            </Marcas1Grid>
        </Marcas1Wrapper>
    </FlexWrapper>
}