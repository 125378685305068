import styled from "@emotion/styled"
import { Heading, MiembroDeEquipo } from "components"
import { Paragraph } from "components/Paragraph"
import { Subheading } from "components/Subheading"

const FlexWrapper = styled.div`
    width: 100%;
    z-index: 4;
    display: flex;
    padding-left: 12.5%;
`

const ParteDelEquipoWrapper = styled.div`
    width: 80%;
    color: #191c52;
`

const HeadingMargin = styled.div`
    height: 230px;
`

const SubheadingMargin = styled.div`
    height: 5%;
`

const EquipoFlex = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 14%;
    position: relative;
`

export const ParteDelEquipo = () => {
    return <FlexWrapper>
        <ParteDelEquipoWrapper>
            <Heading>Parte Del Equipo</Heading>
            <HeadingMargin />
            <EquipoFlex>
                <MiembroDeEquipo name="Julieta Dell Acqua" title="co founder / ceo" style={{ position: "absolute", top: 0, width: 700 }}>
                    <>
                        Durante los últimos veinte años pertenecí al mundo corporativo, liderando gerencias de Customer Experience y Trade Marketing en empresas multinacionales, parte de mis desafíos fue lograr incentivar, motivar y fidelizar equipos internos, partners estratégicos y clientes con el fin de poder cumplir con los objetivos propuestos.
                        <br />Fue ahí, donde me convencí que el mejor resultado se logra generando relaciones de confianza, honestas, duraderas, cercanas y rentables.
                        <br />Ahora, desde el otro lado del escritorio, lidero Hilo, una agencia pensada para acompañar, con ambas miradas, el diseño e implementacion de estrategias de loyalty marketing.
                    </>
                </MiembroDeEquipo>
                <MiembroDeEquipo name="Guido Azzimonti" title="co founder / director creativo" style={{ position: "absolute", top: 475, width: 560 }}>
                    <>
                    Hace más de veinte años me desenvuelvo en el mercado publicitario, liderando agencias de marketing de primera línea. Gracias a variados proyectos con clientes, me convertí en referente de los programas de fidelización y relacionamiento para empresas como Claro, Samsung y Peugeot entre otras.
                    <br />El pensamiento creativo y estratégico me caracterizan.
                    </>
                </MiembroDeEquipo>
                <MiembroDeEquipo name="Daniela Molinero" title="co founder / directora de cuentas" style={{ position: "absolute", top: 825, width: 500 }}>
                    <>
                    Tengo más de quince años de experiencia en Marketing y me especializo en el trabajo con equipos en formato agile para integrar y fusionar ideas, ejecutando proyectos multi áreas y de gran desarrollo.
                    <br /><br />Gracias a todos estos años como líder, desarrollé la profunda capacidad de identificar el resultado que se busca, y lograr que las cosas sucedan.
                    <br /><br />Mi receta es conectar, a través de relaciones profesionales, honestas y de confianza, a los distintos players, gestionando los tiempos estipulados, la calidad de los entregables y siempre lograr los objetivos propuestos.
                    </>
                </MiembroDeEquipo>
            </EquipoFlex>
        </ParteDelEquipoWrapper>
    </FlexWrapper>
}