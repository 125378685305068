import Desktop from 'pages/Desktop';
import { Mobile } from 'pages/Mobile';
import React from 'react';
import { useWindowSize } from 'react-use';

function App() {
  const { width } = useWindowSize();

  const pathWidth = 1200

  if (width >= pathWidth) return <Desktop />
  else return <Mobile />
}

export default App;
