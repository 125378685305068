import styled from "@emotion/styled"
import { ComoItem, Heading } from "components"
import { Subheading } from "components/Subheading"
import fidelizacionSrc from "assets/fidelizacion.svg"
import incentivosSrc from "assets/incentivos.svg"
import accionesDeRelacionamientoSrc from "assets/acciones-de-relacionamiento.svg"

const FlexWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #191c52;
`

const ComoWrapper = styled.div`
    width: 80%;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #191c52;
`

const HeadingMargin = styled.div`
    height: 150px;
`

const SubheadingMargin = styled.div`
    height: 80px;
`

const ComoItemFlex = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`
export const Como = () => {
    return <FlexWrapper>
        <Heading>Servicios</Heading>
        <HeadingMargin />
        <ComoWrapper>
            <Subheading>Diseñando e implementando programas de:</Subheading>
            <SubheadingMargin />
            <ComoItemFlex>
                <ComoItem title="Fidelización" logoSrc={fidelizacionSrc}>
                    <>Programas que premien a tus clientes leales. Identificando y potenciando Brand Lovers, esos consumidores emocionalmente vinculados con la marca.</>
                </ComoItem>
                <ComoItem title="Incentivos" logoSrc={incentivosSrc}>
                    <>Motivar y acelerar el comportamiento productivo de tus equipos internos o partners estratégicos, logrando mejores resultados de eficiencia, desempeño y fidelidad con la marca.</>
                </ComoItem>
                <ComoItem title="Acciones de relacionamiento" logoSrc={accionesDeRelacionamientoSrc}>
                    <>Diseñamos acciones de relacionamiento estratégico, experiencias, acciones BTL y eventos, para dar soporte a los distintos programas de Loyalty.</>
                </ComoItem>
            </ComoItemFlex>
        </ComoWrapper>
    </FlexWrapper>
}