import styled from "@emotion/styled"

import premiosClaroSrc from "assets/premios-claro.jpeg"
import olimpiadasNacionalesSrc from "assets/olimpiadas-nacionales.jpeg"
import personalViajesSrc from "assets/personal-viajes.jpeg"
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css/pagination";
import "./overrides.css";
import { Pagination } from "swiper";

const FlexWrapper = styled.div`
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
`

const ImagenesFlex = styled.div`
    width: 85%;
    max-width: 85%;
    display: flex;
    justify-content: space-between;
    margin-top: -50px;
`


export const FlexImagenes = () => {
    
    return <FlexWrapper>
        <ImagenesFlex>
            <Swiper
                spaceBetween={50}
                slidesPerView={1}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                modules={[Pagination]}
                pagination={
                    {
                        bulletActiveClass: "white-bullet"
                    }
                }
                
            >
                <SwiperSlide style={{ display: "flex", justifyContent: "center" }}>
                    <img width={300} height={200} src={premiosClaroSrc} />
                </SwiperSlide>
                <SwiperSlide style={{ display: "flex", justifyContent: "center" }}>
                    <img width={300} height={200} src={olimpiadasNacionalesSrc} />
                </SwiperSlide>
                <SwiperSlide style={{ display: "flex", justifyContent: "center" }}>
                    <img width={300} height={200} src={personalViajesSrc} />
                </SwiperSlide>

            </Swiper>
        </ImagenesFlex>
    </FlexWrapper>
}