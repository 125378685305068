import styled from "@emotion/styled";

export const Heading2 = styled.h1`
    font-family: "HelveticaNeueLTStd-Blk";
    font-size: 1.5rem;
    line-height: 1.9rem;
    text-transform: uppercase;
    font-weight: bold;
    @media (max-width: 1200px) {
        font-size: 0.75rem;
        line-height: 0.9rem;
    }
`