import styled from "@emotion/styled"
import { useState } from "react"
import { Transition } from "react-transition-group"
import { Heading } from "components"
import { Paragraph } from "components/Paragraph"
import { Subheading } from "components/Subheading"

const FlexWrapper = styled.div`
    width: 100%;
    z-index: 4;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #191c52;
    padding-top: 20px;
`

const QueHacemosWrapper = styled.div`
    width: 80%;
`

const HeadingMargin = styled.div`
    height: 20px;
`

const SubheadingMargin = styled.div`
    height: 20px;
`

export const QueHacemos = () => {
    return <FlexWrapper>
        <Heading>Qué Hacemos</Heading>
        <HeadingMargin />
        <QueHacemosWrapper>
            <Subheading>Fortalecemos relaciones, fidelizamos,
                motivamos e incentivamos a clientes,
                equipos internos y partners estratégicos.</Subheading>
            <SubheadingMargin />
            <Paragraph>Somos una agencia especializada en Loyalty
                Marketing. Nuestra propuesta de valor es entender la
                necesidad, diseñar y ejecutar las mejores estrategias
                de fidelización, incentivos y relacionamiento de
                marcas, proyectos o empresas.</Paragraph>
        </QueHacemosWrapper>
    </FlexWrapper>
}