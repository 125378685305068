import styled from "@emotion/styled"
import icon from "./icon.png"

export const CloseButton = styled.button`
    border: none;
    border-radius: 50%;
    padding: 10px;
    background: white url("${icon}") no-repeat center;
    background-size: 50%;
    width: 60px;
    height: 60px;
    cursor: pointer;
`