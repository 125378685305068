import styled from "@emotion/styled";
import {
  Drawer,
  Heading2,
  HiloMobile,
  Logo,
  MenuButton,
  WhatsappButton,
} from "components";
import { useState } from "react";
import { OtrosServicios } from "sections/Mobile";
import {
  Como,
  QueHacemos,
  FlexImagenes,
  ComoTrabajamos,
  NuestroAdn,
  ParteDelEquipo,
  Marcas1,
  Marcas2,
  Conectamos,
  ContactInfo,
} from "sections/Mobile";

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 340px;
  max-width: 100vw;
  width: 100%;
`;

const InnerContainer = styled.div`
  position: relative;
  min-width: 340px;
  max-width: 340px;
  z-index: 5;
`;
const ImagesFlexMargin = styled.div`
  /* height: 78px; */
  height: 247px;
`;

const PinkContainer = styled(Container)`
  background-color: #ed008e;
  width: 100%;
`;

const BlueContainer = styled(Container)`
  width: 100%;
  background-color: #191c52;
`;

const HiloContainer = styled(Container)`
  position: absolute;
  z-index: 3;
`;

const Contactanos = styled(Heading2)`
  font-size: 1.5rem !important;
  color: white;
  padding-top: 156px;
  padding-left: 95px;
`;

export const Mobile = () => {
  const pathWidth = 340;
  //const height = 15241.912 / 1900.801 * pathWidth
  const height = (6402 * 340) / 380; //16280 / 1896 * pathWidth
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <>
      <MenuButton onClick={() => setDrawerOpen(true)} />
      <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)} />
      <WhatsappButton onClick={()=>window.open("https://api.whatsapp.com/send/?phone=5491158677154", "_blank")}/>
      <HiloContainer style={{ top: 154 }}>
        <InnerContainer>
          <HiloMobile width={pathWidth} height={height} />
        </InnerContainer>
      </HiloContainer>
      <Container style={{ top: 0 }}>
        <InnerContainer style={{ zIndex: 2 }}>
          <Logo width={150} height={150} />
        </InnerContainer>
      </Container>
      <Container style={{ top: 680, marginLeft: "-10px" }}>
        <InnerContainer id="que-hacemos">
          <QueHacemos />
        </InnerContainer>
      </Container>
      <Container style={{ top: 1030 }}>
        <InnerContainer id="servicios">
          <Como />
        </InnerContainer>
      </Container>
      <PinkContainer style={{ top: 1458, paddingBottom: 88 }}>
        <InnerContainer>
          {/* <FlexImagenes /> */}
          <ImagesFlexMargin />
          <ComoTrabajamos />
        </InnerContainer>
      </PinkContainer>
      <Container style={{ top: 2590 }}>
        <InnerContainer>
          <NuestroAdn />
        </InnerContainer>
      </Container>
      <PinkContainer style={{ top: 2860, paddingBottom: 20 }}>
        <InnerContainer>
          <OtrosServicios />
        </InnerContainer>
      </PinkContainer>
      <Container style={{ top: 3106 }}>
        <InnerContainer id="equipo">
          <ParteDelEquipo />
        </InnerContainer>
      </Container>
      <Container style={{ top: 3950 }}>
        <InnerContainer id="pymes">
          <Marcas1 />
        </InnerContainer>
      </Container>
      <Container style={{ top: 4493 }}>
        <InnerContainer>
          <Marcas2 />
        </InnerContainer>
      </Container>
      <PinkContainer style={{ top: 4840, paddingBottom: 100, paddingTop: 100 }}>
        <InnerContainer>
          <Conectamos />
        </InnerContainer>
      </PinkContainer>
      <BlueContainer style={{ top: 5125, paddingBottom: 120, paddingTop: 160 }}>
        <InnerContainer id="contactanos">
          <ContactInfo />
          <Contactanos>Contactanos</Contactanos>
        </InnerContainer>
      </BlueContainer>
    </>
  );
};
