import styled from "@emotion/styled";

export const TextInput = styled("input")`
    border: 1px solid white;
    font-size: 1.2rem;
    padding: 16px;    
    background: none;
    border-radius: 12px;
    z-index: 10;
    outline: none;
    color: white;
    font-family: 'HelveticaNeueLTStd-Bd';
    width: 100%;
`

export const TextAreaInput = styled("textarea")`
    border: 1px solid white;
    font-size: 1.2rem;
    padding: 16px;    
    background: none;
    border-radius: 12px;
    z-index: 10;
    outline: none;
    color: white;
    font-family: 'HelveticaNeueLTStd-Bd';
    width: 100%;
`