import styled from "@emotion/styled"
import icon from "./icon.png"

export const MenuButton = styled.button`
    border: solid 1px #9FCB39;
    border-radius: 8px;
    padding: 10px;
    background: white url("${icon}") no-repeat center;
    background-size: 60%;
    width: 50px;
    height: 50px;
    position: fixed;
    top: 50px;
    left: 40px;
    z-index: 11;
    cursor: pointer;
    @media (max-width: 1200px) {
        top: 20px;
        left: 15px;
    }
`